<template>
  <div class="user">
    <div class="header">
      <img src="@/assets/images/user/peopleCenter/center.png" alt />
    </div>
    <div class="main">
      <div class="left">
        <div class="left-h">
          <div class="lhh">
            <div class="hl">
              <img
                class="img2"
                v-if="portrait == null"
                src="~@/assets/images/user/head.jpg"
                alt
              />
              <img v-else :src="portrait" alt class="img2" />
              <img
                src="@/assets/images/user/peopleCenter/yellow.png"
                alt
                class="img1"
              />
              <div class="lv">LV:{{ spreadGrade }}</div>
            </div>
            <div class="hr">
              <div class="hr-h">
                <div
                  style="
                    font-size: 20px;
                    width: 82px;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                  "
                >
                  {{ username }}
                </div>
              </div>
              <div class="hr-m">ID:{{ userid }}</div>
              <img
                src="@/assets/images/user/peopleCenter/bg4.png"
                alt
                class="hr-f"
              />
            </div>
            <div>
              <el-upload
                class="upload-demo"
                action="http://124.70.65.49/prod-api/api/user/updateUserAvatar"
                :on-change="handleChange"
                :headers="header"
                :file-list="fileList"
              >
                <el-button size="small" type="primary">点击上传</el-button>
                <!-- <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过500kb</div> -->
              </el-upload>
            </div>
          </div>
          <div class="lhf">
            <img
              width="20px"
              height="20px"
              src="../../assets/images/user/peopleCenter/gold.png"
              alt
            />
            <div style="margin-left: 10px">{{ money }}</div>
          </div>
        </div>
        <div class="left-f">
          <div
            @click="$router.push({ name: 'personalCenter' })"
            :class="`${$route.name === 'personalCenter' ? 'yes' : ''}`"
          >
            个人明细
          </div>
          <div
            @click="$router.push({ name: 'backpack' })"
            :class="`${$route.name === 'backpack' ? 'yes' : ''}`"
          >
            我的背包
          </div>
          <div
            @click="$router.push({ name: 'house' })"
            :class="`${$route.name === 'house' ? 'yes' : ''}`"
          >
            我的仓库
          </div>
          <div
            @click="$router.push({ name: 'bill' })"
            :class="`${$route.name === 'bill' ? 'yes' : ''}`"
          >
            提货信息
          </div>
          <div
            @click="$router.push({ name: 'detail' })"
            :class="`${$route.name === 'detail' ? 'yes' : ''}`"
          >
            收支明细
          </div>
        </div>
      </div>
      <div class="right">
        <router-view></router-view>
      </div>
    </div>
    <div class="footer">
      <img
        src="../../assets/images/user/peopleCenter/fanhui.png"
        alt
        @click="$router.push({ name: 'Home' })"
      />
    </div>
  </div>
</template>

<script>
import { getToken } from "./../../api/cookie";
import { getUserInfo } from "./../../api/index";
export default {
  data() {
    return {
      portrait: "",
      username: "",
      userid: "",
      money: 0,
      spreadGrade: 0,
      status: 1,
      IsCocropper: false,
      headImg: "", //剪切图片上传
      crap: false,
      previews: {},
      option: {
        img: "",
        outputSize: 1, //剪切后的图片质量（0.1-1）
        full: false, //输出原图比例截图 props名full
        outputType: "png",
        canMove: true,
        original: false,
        canMoveBox: false,
        autoCrop: true,
        fixedBox: true,
        autoCropWidth: 200,
        autoCropHeight: 150,
      },
      fileName: "", //本机文件地址
      imgFile: {},
      uploadImgRelaPath: "", //上传后的图片的地址（不带服务器域名）
      header: {
        userType: "user",
        Authorization: "",
      },
      fileList: [],
    };
  },
  methods: {
    handleChange(file, fileList) {
      this.fileList = fileList.slice(-3);
    },

    // 实时预览函数
    realTime(data) {
      // console.log(data);
      this.previews = data;
    },
  },
  mounted() {
    this.header.Authorization = getToken();
    getUserInfo().then((res) => {
      // console.log(res.data.user);
      this.spreadGrade = res.data.user.spreadGrade;
      this.money = res.data.user.bean;
      this.username = res.data.user.name;
      this.portrait = res.data.user.portrait;
      console.log(this.portrait);
      this.userid = res.data.user.id;
    });
  },
};
</script>

<style scoped lang="scss">
/deep/.el-button {
  background-color: #000000;
}
@media (max-width: 550px) {
  /deep/.el-button {
    background-color: #000000;
    margin-left: 50px;
    margin-top: 20px;
  }
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.user {
  .header {
    width: 650px;
    height: 74px;
    padding-top: 10px;
    margin: 20px auto;
    background-image: url("../../assets/images/user/peopleCenter/bg1.png");
    background-size: 100% 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .main {
    width: 1130px;
    height: 458px;
    margin: auto;
    margin-top: -70px;
    background-image: url("../../assets/images/user/peopleCenter/bg2.png");
    display: flex;

    .left {
      margin-top: 80px;
      margin-left: 150px;
      width: 250px;
      height: 300px;

      .left-h {
        height: 80px;
        width: 100%;

        .lhh {
          height: 60px;
          padding-left: 30px;
          display: flex;

          .hl {
            width: 50px;
            height: 50px;
            border-radius: 50%;
            position: relative;
            border: 1px solid rgb(236, 248, 1);

            .img2 {
              border-radius: 50%;
              width: 100%;
              height: 100%;
            }

            .img1 {
              display: block;
              position: absolute;
              z-index: 2;
              left: 10px;
              bottom: 0px;
              width: 30px;
              height: 15px;
            }

            .lv {
              position: absolute;
              z-index: 3;
              left: 12px;
              bottom: 0px;
              font-size: 10px;
              font-weight: none;
            }
          }

          .hr {
            margin-left: 10px;
            color: #fff;

            .hr-h {
              display: flex;
              align-items: flex-end;
            }

            .hr-m {
              font-size: 14px;
            }

            .hr-f {
              display: block;
              width: 100%;
            }
          }
        }

        .lhf {
          height: 20px;
          padding-left: 30px;
          //width: 100%;
          display: flex;
          color: rgba(238, 209, 92, 1);
        }
      }

      .left-f {
        height: 200px;
        padding-top: 20px;
        width: 100%;
        color: #fff;
        overflow: auto;
        //background-color: aqua;

        div {
          width: 120px;
          height: 33px;
          margin: 10px;
          font-size: 16px;
          line-height: 33px;
          background-size: 100% 100%;
        }

        .yes {
          text-align: center;
          background-image: url("../../assets/images/user/peopleCenter/bg3.png");
        }
      }
    }

    .right {
      margin-top: 80px;
      margin-left: 80px;
      width: 600px;
      height: 300px;
      //background-color: fuchsia;
    }
  }

  .footer {
    width: 600px;
    height: 74px;
    margin: auto;
    margin-top: -50px;
    background-image: url("../../assets/images/user/peopleCenter/return.png");
    background-size: 100% 100%;
    text-align: center;

    img {
      margin-top: 14px;
    }
  }

  //改变背景色
  .cropper-box {
    background: #333 !important;
  }

  //使截图框变为圆形
  .cropper-crop-box {
    width: 200px !important;
    height: 200px !important;
    overflow: hidden;
    border-radius: 50% !important;
  }

  //截图相关样式
  .cropper-content {
    display: flex;
    display: -webkit-flex;
    justify-content: flex-end;
    -webkit-justify-content: flex-end;

    .cropper {
      width: 300px;
      height: 300px;
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
    }

    .show-preview {
      flex: 1;
      -webkit-flex: 1;
      display: flex;
      display: -webkit-flex;
      justify-content: center;
      -webkit-justify-content: center;

      .preview {
        overflow: hidden;
        border-radius: 50%;
        border: 1px solid #cccccc;
        background: #cccccc;
        margin-left: 40px;
      }
    }
  }
}
@media (max-width: 550px) {
  .user {
    .header {
      display: none;
    }

    .main {
      width: 100%;
      height: auto;
      margin: auto;
      margin-top: -70px;
      background: none;
      display: block;

      .left {
        margin-top: 80px;
        margin-left: 0;
        width: 100%;
        height: 180px;
        background-color: #000216;

        .left-h {
          height: 108px;
          width: 100%;

          .lhh {
            height: 85px;
            padding-left: 30px;
            display: flex;

            .hl {
              width: 80px;
              height: 80px;
              border-radius: 50%;
              position: relative;

              .img2 {
                border-radius: 50%;
                width: 100%;
                height: 100%;
              }

              .img1 {
                display: block;
                position: absolute;
                z-index: 2;
                left: 10px;
                bottom: 0px;
                width: 30px;
                height: 15px;
              }

              .lv {
                position: absolute;
                z-index: 3;
                left: 12px;
                bottom: 0px;
                font-size: 10px;
                font-weight: none;
              }
            }

            .hr {
              margin-left: 10px;
              color: #fff;

              .hr-h {
                display: flex;
                align-items: flex-end;
              }

              .hr-m {
                font-size: 14px;
              }

              .hr-f {
                display: block;
                width: 100%;
              }
            }
          }

          .lhf {
            height: 20px;
            padding-left: 30px;
            //width: 100%;
            display: flex;
            color: rgba(238, 209, 92, 1);
          }
        }

        .left-f {
          height: auto;
          flex-wrap: wrap;
          padding-top: 0px;
          width: 100%;
          color: #fff;
          display: flex;

          div {
            width: 73px;
            height: 20px;
            margin: 10px;
            font-size: 16px;
            line-height: 20px;
            text-align: center;
          }

          .yes {
            text-align: center;
            background-image: url("~@/assets/images/user/peopleCenter/p-bg3.png");
          }
        }
      }

      .right {
        margin: 20px auto !important;
        width: 95%;
        height: 450px;
        //background-color: fuchsia;
      }
    }

    .footer {
      display: none;
    }

    //改变背景色
    .cropper-box {
      background: #333 !important;
    }

    //使截图框变为圆形
    .cropper-crop-box {
      width: 200px !important;
      height: 200px !important;
      overflow: hidden;
      border-radius: 50% !important;
    }

    //截图相关样式
    .cropper-content {
      display: flex;
      display: -webkit-flex;
      justify-content: flex-end;
      -webkit-justify-content: flex-end;

      .cropper {
        width: 300px;
        height: 300px;
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
      }

      .show-preview {
        flex: 1;
        -webkit-flex: 1;
        display: flex;
        display: -webkit-flex;
        justify-content: center;
        -webkit-justify-content: center;

        .preview {
          overflow: hidden;
          border-radius: 50%;
          border: 1px solid #cccccc;
          background: #cccccc;
          margin-left: 40px;
        }
      }
    }
  }
}
</style>
